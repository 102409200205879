import { gql } from "@apollo/client"

export const CREATE_EMAIL_SUBSCRIPTION = gql`
    mutation CreateEmailSubscription($input: CreateEmailSubscriptionInput!) {
        createEmailSubscription(input: $input) {
            id
            email
            isVerified
        }
    }
`

export const VERIFY_EMAIL_SUBSCRIPTION = gql`
    mutation VerifyEmailSubscription($input: VerifyEmailSubscriptionInput!) {
        verifyEmailSubscription(input: $input) {
            id
            email
            isVerified
        }
    }
`

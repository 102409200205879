import React, { useEffect, useReducer } from "react"
import {
    FormattedMessage,
    FormattedHTMLMessage,
    injectIntl
} from "gatsby-plugin-intl"
import { useMutation } from "@apollo/client"

import Layout from "../components/Layout"
import SearchEngineOptimization from "../components/SearchEngineOptimization"
import withLocation from "../hocs/withLocation"
import verifyReducer, { initialState } from "../providers/verify/reducer"
import * as VERIFY_ACTIONS from "../providers/verify/actions"
import { VERIFY_EMAIL_SUBSCRIPTION } from "../schemas/emailSubscription"

const VerifyPage = ({ intl, search }) => {
    const [state, dispatch] = useReducer(verifyReducer, initialState)
    const [verify,] = useMutation(VERIFY_EMAIL_SUBSCRIPTION, {
        onCompleted: (data) => {
            dispatch({ type: VERIFY_ACTIONS.VERIFY_SUCCESS })
        },
        onError: (error) => {
            dispatch({ type: VERIFY_ACTIONS.VERIFY_ERROR })
        }
    })
    const { code, email } = search

    useEffect(() => {
        if (code && email) {
            dispatch({ type: VERIFY_ACTIONS.VERIFY_IN_PROGRESS })
            verify({
                variables: {
                    input: {
                        email,
                        verificationCode: code
                    }
                }
            }).catch(() => {
                dispatch({ type: VERIFY_ACTIONS.VERIFY_ERROR })
            })
        }
    }, [code, email, verify])

    return (
        <Layout pageInfo={{ pageName: "verify" }}>
            <SearchEngineOptimization
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.verify.seo.title" })}
            />
            {!state.finished ? (
                <h1>
                    <FormattedMessage id="pages.verify.title" />
                </h1>
            ) : state.success ? (
                <>
                    <h1>
                        <FormattedMessage id="pages.verify.completed_title" />
                    </h1>
                    <p>
                        <FormattedHTMLMessage id="pages.verify.completed_text" />
                    </p>
                </>
            ) : (
                <>
                    <h1>
                        <FormattedMessage id="pages.verify.error_title" />
                    </h1>
                    <p>
                        <FormattedHTMLMessage id="pages.verify.error_text" />
                    </p>
                </>
            )}
        </Layout>
    )
}

export default withLocation(injectIntl(VerifyPage))

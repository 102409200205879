import { VERIFY_IN_PROGRESS, VERIFY_SUCCESS, VERIFY_ERROR } from "./actions"

const initialState = {
    finished: false,
    success: false
}

const verifyReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_IN_PROGRESS:
            return { ...state, finished: false, success: false }
        case VERIFY_SUCCESS:
            return { ...state, finished: true, success: true }
        case VERIFY_ERROR:
            return { ...state, finished: true, success: false }
        default:
            return state
    }
}

export { initialState }
export default verifyReducer
